import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import Headroom from "react-headroom"

import Navigation from "./navigation"

const Header = ({ siteTitle }) => {
  return (
    <HeaderWrapper>
      <Headroom disableInlineStyles>
        <SiteTitle>
          <Link to="/">{siteTitle}</Link>
        </SiteTitle>
        <Navigation />
      </Headroom>
    </HeaderWrapper>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

const SiteTitle = styled.h1`
  position: relative;
  z-index: 1;
  color: white;
  font-size: 1.5rem;
  margin: 0;

  a {
    color: white;
    &:hover {
      color: ${props => props.theme.color.secondary};
    }
  }
`

const HeaderWrapper = styled.header`
  .headroom {
    height: 88px;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: ${props => props.theme.color.tertiary};
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0;
    width: 100%;
    left: 0;
    right: 0;
    display: flex;
    padding: 1.1rem;
    box-sizing: border-box;
  }
  .headroom--unfixed {
    position: fixed;
    transform: translateY(0);
  }
  .headroom--scrolled {
    transition: transform 200ms ease-in-out;
  }
  .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);
  }
  .headroom--pinned {
    position: fixed;
    transform: translateY(0%);
  }
`

export default Header
