import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const WhatsappFixed = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "whatsApp.png" }) {
        childImageSharp {
          fixed(width: 85, quality: 90) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <WhatsappWrapper>
      <Link to="https://wa.me/31644434847" target="_blank">
        <Img fixed={data.file.childImageSharp.fixed} alt="Whatsapp Logo" />
      </Link>
    </WhatsappWrapper>
  )
}

const WhatsappWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 20;
  @media (min-width: ${props => props.theme.breakpoint.desktop}) {
    display: none;
  }
`
export default WhatsappFixed
