import { Link } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components"

const Navigation = () => {
  const [isOpen, setNav] = useState(false)

  const toggleNav = () => {
    setNav(isOpen => !isOpen)
  }

  return (
    <>
      <NavWrapper>
        <div className="masthead">
          <button
            className={
              isOpen
                ? `${"toggle-btn"} ${"toggle-btn-active"}`
                : `${"toggle-btn"}`
            }
            type="button"
            onClick={toggleNav}
            aria-label="Menu Button"
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>

        <ul
          className={isOpen ? `${"nav-links"} ${"show-nav"}` : `${"nav-links"}`}
        >
          <li key={0}>
            <Link activeClassName="active" to="/">
              Home
            </Link>
          </li>
          <li key={1}>
            <Link activeClassName="active" to="/werkzaamheden">
              Werkzaamheden
            </Link>
          </li>
        </ul>
      </NavWrapper>
    </>
  )
}

const NavWrapper = styled.nav`
  .masthead {
    position: relative;
    z-index: 1;
    width: 100%;
    justify-content: space-between;

    a {
      text-transform: capitalize;
      color: #fff;
      letter-spacing: 1px;
      line-height: 2.55;
    }

    @media (min-width: 768px) {
      a {
        line-height: 2.4;
        letter-spacing: 2.9px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    text-align: center;
    background-color: ${props => props.theme.color.tertiary};
    margin: 0;
    height: calc(100vh + 88px);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(100%);
    transition: 0.3s ease-in;
    list-style: none;
    padding-left: 0;

    li {
      list-style: none;
      font-size: 1.25rem;
      font-weight: 400;
      margin-left: 0;
      padding: 0.75rem 0;
      min-width: 6rem;
      a {
        text-transform: capitalize;
        color: #fff;
        transition: 0.3s;
        &.active {
          color: ${props => props.theme.color.secondary};
        }
      }
      &:hover {
        cursor: pointer;
        a {
          color: ${props => props.theme.color.secondary};
        }
      }
    }

    &.show-nav {
      transform: translateX(0%);
    }
  }
  .toggle-btn {
    width: 40px;
    height: 40px;
    padding: 5px;
    background-color: transparent;
    border: none;

    span {
      display: block;
      width: 30px;
      height: 2px;
      background-color: #fff;
      transition: 0.2s ease-in;

      &:nth-child(1) {
        transform: translateY(-5px);
      }

      &:nth-child(3) {
        transform: translateY(5px);
      }
    }

    &.toggle-btn-active {
      span {
        &:nth-child(1) {
          transform: translateY(2px) rotate(45deg);
        }

        &:nth-child(2) {
          max-width: 0;
        }

        &:nth-child(3) {
          transform: translateY(-2px) rotate(-45deg);
        }
      }
    }
  }

  @media (min-width: 992px) {
    .masthead {
      flex-direction: column;
      justify-content: center;
    }

    .toggle-btn {
      display: none;
    }

    .nav-links {
      display: flex;
      height: 100%;
      background: transparent;
      flex-direction: row;
      margin-left: auto;
      position: relative;
      transform: translateX(0);
      transition: none;

      li {
        margin-left: 1rem;
      }
    }
  }
`

export default Navigation
