/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import WhatsappFixed from "../contact/whatsappFixed"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          author
        }
      }
    }
  `)

  return (
    <>
      <WhatsappFixed />
      <Header
        siteTitle={
          data.site.siteMetadata?.title || `Hondentrimsalon Astrid Zandbergen`
        }
      />
      <main>{children}</main>
      <Footer
        siteTitle={
          data.site.siteMetadata?.title || `Hondentrimsalon Astrid Zandbergen`
        }
        author={data.site.siteMetadata?.author || `@willemstefan`}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
