import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const Footer = ({ siteTitle, author }) => {
  return (
    <FooterWrapper>
      <p>
        &copy; <Link to="/">{siteTitle}</Link>
      </p>
    </FooterWrapper>
  )
}

const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.color.tertiary};
  color: ${props => props.theme.color.primary};
  padding: 0.6rem;
  text-align: center;

  a {
    color: #fff;
    &:hover {
      color: ${props => props.theme.color.secondary};
    }

    svg {
      vertical-align: middle;
    }
  }
`

export default Footer
